import React, { FC, useEffect, useState } from "react"
import Modal from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Label from "@components/ui/Label/Label"
import TextField from "@components/ui/TextField/TextField"
import { useAlert } from "react-alert"
import Icon from "@components/ui/Icon/Icon"
import { ID } from "@framework/types/types"
import {
  GenerateReportLink,
  ReportShareEntity,
} from "@services/creative-analytics"
import { observer } from "mobx-react-lite"
import styles from "./Modals.module.scss"

interface EditReportNameModalProps {
  isOpen: boolean
  reportId: number
  accountId: number | null
  reportToken: string | null
  onClose: () => void
  onShareReport: (accountId: ID, reportId: ID, data: ReportShareEntity) => void
  onGenerateGuestToken: (accountId: ID, reportID: ID) => void
}

function isEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

const ShareReportModal: FC<EditReportNameModalProps> = observer(
  ({
    onClose,
    isOpen,
    reportId,
    accountId,
    reportToken,
    onShareReport,
    onGenerateGuestToken,
  }) => {
    const alert = useAlert()
    const [email, setEmail] = useState<string>("")
    const [error, setError] = useState<boolean>(false)

    const handleShareReport = () => {
      if (!email || !isEmail(email)) {
        alert.error("Please provide the correct email")
        setError(true)
        return
      }
      if (accountId && reportId && reportToken) {
        const reportLink = GenerateReportLink(
          window.location.origin,
          accountId,
          reportId,
          reportToken
        )
        onShareReport(accountId, reportId, {
          email,
          link: reportLink,
        })
      }
      setError(false)
      setEmail("")
    }

    const copyShareLink = async () => {
      if (accountId && reportId && reportToken) {
        const reportLink = GenerateReportLink(
          window.location.origin,
          accountId,
          reportId,
          reportToken
        )
        await navigator.clipboard.writeText(reportLink)
        alert.success("Link successfully copied to clipboard")
      }
    }

    useEffect(() => {
      if (reportToken) return
      if (accountId && reportId) onGenerateGuestToken(accountId, reportId)
    }, [accountId, reportId])

    return (
      <Modal
        title="Share report"
        isOpen={isOpen}
        onClose={() => {
          setError(false)
          setEmail("")
          onClose()
        }}
        className={styles.modalBase}
      >
        <Stack align="stretch">
          <Stack direction="column" justify="space-between" align="stretch">
            <Label label="E-MAIL ADDRESS">
              <TextField
                error={error}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter e-mail address"
              />
            </Label>
          </Stack>
          <Stack direction="row" justify="space-between">
            <Button color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Stack direction="row" justify="flex-end">
              <Button
                color="secondary"
                before={<Icon name="link" />}
                onClick={copyShareLink}
              >
                Copy link
              </Button>
              <Button color="primary" onClick={handleShareReport}>
                Share
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    )
  }
)

export default ShareReportModal
