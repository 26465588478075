/* eslint-disable jsx-a11y/no-autofocus */
import React from "react"

import SimpleOption from "@components/ui/DropDown/Option"
import Select from "@components/ui/DropDown"
import NoData from "@components/ui/NoData/NoData"
import { capitalizeFirstLetter } from "@utils/stringUtils"
import BaseField from "@components/ui/TextField/BaseField"
import Typography from "@components/ui/Typography/Typography"
import useOutsideClick from "@framework/hooks/useOutsideClick"

type OptionType<T> = T | { title: string; items?: T[] }

export interface SimpleSelectProps<T extends string = string> {
  value?: T
  options: OptionType<T>[]
  className?: string
  inputClassName?: string
  placeholder?: string
  disabled?: boolean
  withSearch?: boolean
  query?: string
  edited?: boolean
  error?: boolean
  titleItems?: boolean
  onSearch?: (value: string, e?: React.ChangeEvent) => void
  onSelect?: (value: T, e?: React.MouseEvent) => void
  isSelected?: (value: T, selected?: T) => boolean
  renderBefore?: (value: T) => React.ReactNode
  renderValue?: (value: T) => React.ReactNode
  renderOption?: (value: T) => React.ReactNode
  onClickOutside: () => void
}

const SimpleFilterSelect = <T extends string = string>({
  options,
  value,
  className,
  inputClassName,
  placeholder = "Select...",
  disabled,
  withSearch = false,
  query,
  edited,
  error,
  onSearch,
  isSelected = (value, selected) => selected === value,
  onSelect,
  renderBefore,
  renderValue = capitalizeFirstLetter,
  renderOption = renderValue,
  onClickOutside,
  titleItems = false,
}: React.PropsWithChildren<SimpleSelectProps<T>>) => {
  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    onSearch?.(e.target.value, e)

  const ref = useOutsideClick(() => onClickOutside())

  const renderOptions = (option: OptionType<T>) => {
    if (typeof option === "object") {
      return (
        <div key={option.title}>
          <SimpleOption>
            <Typography type="h4">{renderOption(option.title as T)}</Typography>
          </SimpleOption>
          {option.items
            ? option.items.map((it) => (
                <SimpleOption
                  isSelected={isSelected(it, value)}
                  onClick={(e) => onSelect?.(it, e)}
                  isSubItem
                  key={it}
                >
                  {renderOption(it)}
                </SimpleOption>
              ))
            : []}
        </div>
      )
    }
    return (
      <SimpleOption
        isSelected={isSelected(option as T, value)}
        onClick={(e) => onSelect?.(option as T, e)}
        before={renderBefore?.(option as T)}
        key={option as T}
      >
        {titleItems ? (
          <Typography type="h4">{renderOption(option as T)}</Typography>
        ) : (
          renderOption(option as T)
        )}
      </SimpleOption>
    )
  }

  return (
    <div className={className} ref={ref}>
      {withSearch && (
        <BaseField
          error={error}
          edited={edited}
          disabled={disabled}
          className={inputClassName}
        >
          {withSearch ? (
            <input
              autoFocus
              value={query}
              placeholder={placeholder ?? `Search...`}
              onChange={handleSearch}
            />
          ) : value ? (
            <Typography type="inherit">{renderValue(value)}</Typography>
          ) : (
            <Typography color="black70Color" type="body1">
              {placeholder}
            </Typography>
          )}
        </BaseField>
      )}
      <Select.Box>
        <Select.ItemsList>
          {options.length > 0 ? (
            <Select.Group>{options.map(renderOptions)}</Select.Group>
          ) : (
            <NoData>List is empty</NoData>
          )}
        </Select.ItemsList>
      </Select.Box>
    </div>
  )
}

export default SimpleFilterSelect
