import React from "react"
import clsx from "clsx"
import get from "lodash/get"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import {
  MetricDescription,
  MetricsTrendType,
  MetricType,
} from "@framework/types/metrics"
import NoData from "@components/ui/NoData/NoData"
import Image from "@components/ui/Image/Image"
import { MetaCampaignStatus } from "@pages/MetaAdsChannel/types"
import StatusBadge from "@pages/MetaAdsChannel/components/StatusBadge"
import PlayIcon from "@root/assets/svgIcons/PlayIcon"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import MetricValueChip from "../MetricValueChip"
import ReportTitle from "../ReportTitle"

import styles from "./ReportCard.module.sass"

interface ReportCardProps {
  data: AnalyticsCard
  metricsTrend?: MetricsTrendType
  onOpenKPIChart?: (
    id: string,
    name: string,
    status: MetaCampaignStatus
  ) => void
  onOpenVideoPreview: (data: AnalyticsCard) => void
  metrics?: MetricDescription[]
  active?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler
  className?: string
}

export const ReportCard: React.FC<ReportCardProps> = ({
  data,
  metricsTrend,
  onOpenKPIChart,
  onOpenVideoPreview,
  active = false,
  disabled = false,
  onClick,
  metrics,
  className,
}) => (
  <Box
    className={clsx(styles.root, className, {
      [styles.active]: active,
      [styles.disabled]: disabled,
      [styles.clickable]: !!onClick,
    })}
    onClick={onClick}
    elevation={1}
  >
    <div className={styles.header}>
      <ReportTitle textClassName={styles.link}>{data.Name}</ReportTitle>

      <Button variant="ghost" color="secondary" size="small">
        <Icon name="three-dots" />
      </Button>
    </div>

    <StatusBadge status={data.Status} />

    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
    <div
      className={styles.previewContainer}
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        if (data.Type === "IMAGE") return
        onOpenVideoPreview(data)
      }}
    >
      {data.Type === "IMAGE" ? (
        <Image src={data.ThumbnailURL} className={styles.image} alt="Preview" />
      ) : data.Type === "VIDEO" ? (
        <div className={styles.previewVideo}>
          <div className={styles.previewVideoIcon}>
            <PlayIcon />
          </div>
          <Image
            src={data.ThumbnailURL}
            className={styles.image}
            alt="Preview"
          />
        </div>
      ) : null}

      <Typography
        className={styles.views}
        type="caption3"
        color="black03Color"
        title="People are watching"
      >
        <Icon name="eye" />
        {/* {numberWithCommas(data.totalViews ?? 0)} */}
      </Typography>
    </div>

    <Stack
      className={styles.metricsContainer}
      gutter="4"
      direction="column"
      align="stretch"
    >
      <Stack direction="row" justify="space-between" align="center">
        <Typography color="primary" type="h4" weight="bold">
          Metrics
        </Typography>

        <Button
          size="small"
          color="primary"
          variant="outlined"
          before={<Icon name="statistics" />}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            if (onOpenKPIChart)
              onOpenKPIChart(
                data.Id,
                data.AdsetName,
                data.Status as MetaCampaignStatus
              )
          }}
        >
          KPI Trends
        </Button>
      </Stack>

      <Stack
        className={styles.metricHeader}
        direction="row"
        justify="space-between"
        align="center"
      >
        <Typography color="primary" weight="bold" type="caption2">
          Campaign
        </Typography>
        <Typography className={styles.metricName} weight="bold" type="caption2">
          {data.CampaignName}
        </Typography>
      </Stack>
      <Stack
        className={styles.metricHeader}
        direction="row"
        justify="space-between"
        align="center"
      >
        <Typography color="primary" weight="bold" type="caption2">
          Ad Set
        </Typography>
        <Typography className={styles.metricName} weight="bold" type="caption2">
          {data.AdsetName}
        </Typography>
      </Stack>

      {metrics?.map((metric) => {
        const value = get(data, metric.name, null)
        return (
          <Stack
            direction="row"
            justify="space-between"
            align="center"
            key={metric.name}
          >
            <Typography color="primary" weight="bold" type="caption2">
              {metric.label}
            </Typography>

            <Typography weight="bold" type="caption2">
              <MetricValueChip
                className={styles.metricChip}
                value={value as MetricType}
                metric={metric}
                metricsTrend={metricsTrend as MetricsTrendType}
              />
            </Typography>
          </Stack>
        )
      }) ?? <NoData />}
    </Stack>
  </Box>
)

export default ReportCard
