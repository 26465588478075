import { DEF_YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import { Period } from "@framework/types/types"
import moment from "moment"
import { initArray } from "./numberUtils"

export const maxDate = (d1: Date, d2: Date) =>
  moment(d1).unix() > moment(d2).unix() ? d1 : d2

export const minDate = (d1: Date, d2: Date) =>
  moment(d1).unix() <= moment(d2).unix() ? d1 : d2

export const printDate = (
  date: Date | string | null | undefined,
  format = "MMM DD, YYYY"
) => (date != null ? moment(date).format(format) : "N/A")

export const printDateRange = (range: [Date, Date], format = "MMM DD, YYYY") =>
  range.map((date) => moment(date).format(format)).join(" - ")

export const printMomentRange = (
  range: [moment.Moment, moment.Moment],
  format = "MMM DD, YYYY"
) => range.map((date) => date.format(format)).join(" - ")

export const printDateRangeWithDiff = (
  range: [Date, Date],
  unit: "day" | "week" | "month" = "day"
) => `${printDateRange(range)} (${periodLength(range, unit)} ${unit})`

export const printOnlyDateRange = (
  range: [Date, Date],
  unit: "day" | "week" | "month" = "day"
) => `${printDateRange(range)}`

export const secondsToRemainText = (time: number) => {
  const leftTime = moment(new Date()).startOf("day").add(time, "seconds")
  const hours = leftTime.hours()
  const minutes = leftTime.minutes()

  return `${hours ? `${hours} hours ` : ""} ${
    hours < 1 && minutes < 1
      ? `Less than a minute remaining`
      : `${minutes} minutes remaining`
  }`
}

export const datePairToPeriod = (range: Date[], format: string): Period => ({
  from: moment(range[0]).format(format),
  to: moment(range[1]).format(format),
})

export const periodicityToUnit = (
  periodicity: PerformanceReportPeriodicity
): "day" | "week" | "month" => {
  if (periodicity === "monthly") return "month"
  if (periodicity === "weekly") return "week"
  return "day"
}

export const unitToIsoUnit = <U extends "week" | string>(
  unit: U
): "isoWeek" | U => {
  if (unit === "week") return "isoWeek"
  return unit
}

export const periodLength = (
  period: [Date, Date],
  unit: "day" | "week" | "month" = "month"
): number => {
  const isoUnit = unitToIsoUnit(unit)

  const fromMoment = moment(period[0]).startOf(isoUnit)
  const toMoment = moment(period[1]).endOf(isoUnit)

  return toMoment.diff(fromMoment, unit) + 1
}

export const generateTimeAxisLabels = (
  from: Date,
  length: number,
  periodicity: PerformanceReportPeriodicity,
  format: string = DEF_YYYY_MM_DD_DATE_FORMAT
): string[] => {
  const unit = periodicityToUnit(periodicity)

  const fromMoment = moment(from).startOf(unitToIsoUnit(unit))

  return initArray(length, (idx) =>
    fromMoment.clone().add(idx, unit).format(format)
  )
}
