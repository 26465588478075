import React, { FC, useState } from "react"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import useOutsideClick from "@framework/hooks/useOutsideClick"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import styles from "./Modals.module.scss"

interface VideoPreviewModalProps {
  data: AnalyticsCard
  onClose: () => void
  onDetailsOpen: (data: AnalyticsCard) => void
}

const VideoPreviewModal: FC<VideoPreviewModalProps> = ({
  data,
  onClose,
  onDetailsOpen,
}) => {
  const ref = useOutsideClick(onClose)
  const [isIframeLoading, setIsIframeLoading] = useState<boolean>(true)
  const handleOnIFrameLoad = () => {
    setIsIframeLoading(false)
  }
  return (
    <section ref={ref} className={styles.modalVideoPreview}>
      <Stack direction="row" justify="space-between">
        <IconButton
          onClick={() => onDetailsOpen(data)}
          variant="contained"
          color="secondary"
        >
          <Icon name="file-info" />
        </IconButton>
        <Button
          color="secondary"
          onClick={onClose}
          after={<Icon name="cross" />}
        >
          Close
        </Button>
      </Stack>
      <div className={styles.modalVideoPreviewIframeWrap}>
        {isIframeLoading && (
          <div className={styles.videoLoader}>
            <CircleLoader />
          </div>
        )}
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          src={data.VideoURL}
          className={styles.videoIframe}
          onLoad={handleOnIFrameLoad}
        />
      </div>
    </section>
  )
}

export default VideoPreviewModal
