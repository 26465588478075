import { randomNumber } from "@utils/numberUtils"
import { getPerformanceReportMock } from "@utils/mock-generators"
import { nanoid } from "nanoid"
import { metricNamesList } from "@framework/types/metrics"
import { MetaCampaignReportData, metaCampaignStatusNames } from "./types"

export const generateCampaignReport = (
  idx: number,
  withTrend: boolean = true
): MetaCampaignReportData => ({
  Id: nanoid(),
  // name: `Ultimate-swipe-file-${idx + 1}`,
  CampaignName: `Campaign name`,
  Type: `Type`,
  Name: `Name`,
  AdType: "AdType",
  AdsetName: `Ad Set name`,
  Status:
    metaCampaignStatusNames[randomNumber(0, metaCampaignStatusNames.length)],
  VideoURL: "VideoURL",
  ThumbnailURL:
    "https://gratisography.com/wp-content/uploads/2024/03/gratisography-funflower-800x525.jpg",
  totalViews: randomNumber(1_000, 100_000),
  ...getPerformanceReportMock([...metricNamesList], withTrend),
})

export default null
