import React from "react"
import clsx from "clsx"
import get from "lodash/get"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Box from "@components/ui/Box/Box"
import { MetricsTrendType, MetricType } from "@framework/types/metrics"
import NoData from "@components/ui/NoData/NoData"
import ScrollContainer from "@components/ui/ScrollContainer/ScrollContainer"
import {
  AnalyticsCard,
  defaultMetrics,
} from "@framework/types/creativeAnalytics"
import { metricsDescriptors } from "@framework/constants/metrics"
import { cutStringSize50 } from "@utils/stringUtils"
import MetricCard from "./MetricCard"

import styles from "./DetailsSection.module.sass"

interface DetailsSectionProps {
  data: AnalyticsCard
  metricsTrend: MetricsTrendType
}
const filteredDefaultMetricsDescriptors = metricsDescriptors.filter((md) =>
  defaultMetrics.includes(md.name)
)
const filteredMetricsDescriptors = metricsDescriptors.filter(
  (md) => !defaultMetrics.includes(md.name)
)

export const DetailsSection: React.FC<DetailsSectionProps> = observer(
  ({ data, metricsTrend }) => (
    <Box className={clsx(styles.root)} elevation={1}>
      <section className={styles.header}>
        <Typography type="h4" color="black100Color">
          Details
        </Typography>
      </section>

      <ScrollContainer className={styles.bodyContainer} type="secondary">
        <section className={styles.body}>
          <Stack direction="column" align="stretch" gutter="8">
            <Typography type="h6" color="black70Color" upperCase>
              General
            </Typography>

            <Stack
              direction="column"
              align="stretch"
              gutter="0"
              className={styles.campaignName}
            >
              <Typography type="caption3" color="black70Color">
                Campaign
              </Typography>
              <Typography type="body2" color="black100Color">
                {cutStringSize50(data?.CampaignName)}
              </Typography>

              <Typography type="body2" className={styles.titleTooltip}>
                {data?.CampaignName}
              </Typography>
            </Stack>

            <Stack
              direction="column"
              align="stretch"
              gutter="0"
              className={styles.adsetName}
            >
              <Typography type="caption3" color="black70Color">
                Ad Set Name
              </Typography>
              <Typography type="body2" color="black100Color">
                {cutStringSize50(data?.AdsetName)}
              </Typography>

              <Typography type="body2" className={styles.titleTooltip}>
                {data?.AdsetName}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="column" align="stretch" gutter="8">
            <Typography type="h6" color="black70Color" upperCase>
              Core
            </Typography>

            <div className={styles.grid}>
              {filteredDefaultMetricsDescriptors
                ?.filter((it) => defaultMetrics.includes(it.name))
                .map((metric) => {
                  const value = get(
                    data,
                    metric.name,
                    null
                  ) as MetricType | null
                  return (
                    <MetricCard
                      key={metric.name}
                      value={value}
                      metric={metric}
                      metricsTrend={metricsTrend}
                      withScale
                    />
                  )
                }) ?? <NoData />}
            </div>
          </Stack>

          <Stack direction="column" align="stretch" gutter="8">
            <Typography type="h6" color="black70Color" upperCase>
              Analytics
            </Typography>

            <div className={styles.grid}>
              {filteredMetricsDescriptors
                ?.filter((it) => !defaultMetrics.includes(it.name))
                .map((metric) => {
                  const value = get(
                    data,
                    metric.name,
                    null
                  ) as MetricType | null
                  return (
                    <MetricCard
                      key={metric.name}
                      value={value}
                      metric={metric}
                      metricsTrend={metricsTrend}
                      withScale
                    />
                  )
                }) ?? <NoData />}
            </div>
          </Stack>
        </section>
      </ScrollContainer>
    </Box>
  )
)

export default DetailsSection
