import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"

import { observer } from "mobx-react-lite"
import Divider from "@components/ui/Divider/Divider"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import { NavOption } from "@components/ui/Tabs/types"

import Icon from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/types"
import clsx from "clsx"
import DeleteReportModal from "@pages/MetaAdsChannel/components/Modals/DeleteReportModal"
import useToggle from "@framework/hooks/useToggle"
import EditReportNameModal from "@pages/MetaAdsChannel/components/Modals/EditReportNameModal"
import { useAlert } from "react-alert"
import useOutsideClick from "@framework/hooks/useOutsideClick"
import ShareReportModal from "@pages/MetaAdsChannel/components/Modals/ShareReportModal"
import { ID } from "@framework/types/types"
import { ReportShareEntity } from "@services/creative-analytics"
import styles from "./SidebarMenu.module.scss"

function isValidReportString(str: string) {
  const regex = /^report\/\d+$/
  return regex.test(str)
}

export const personalSettingsOptions: SidebarMenuListItem[] = [
  // { label: "Summary", value: "dashboard", before: <Icon name="dashboard" /> },
  { label: "General", value: "general", before: <Icon name="lightbulb" /> },
  // { label: "Winners", value: "winners", before: <Icon name="vip-crown" /> },
]

// export const companySettingsOptions: NavOption[] = [
//   { label: "Default Report", value: "report/report-1", icon: "file-list" },
//   { label: "Default Report", value: "report/report-2", icon: "file-list" },
//   { label: "Default Report", value: "report/report-3", icon: "file-list" },
// ]
// after?: React.ReactElement | IconName

interface SidebarMenuProps {
  activeRoute?: NavOption
}
interface SidebarMenuListItem {
  label: string
  value: string
  id?: number
  before: React.ReactElement | IconName
  is_read_only?: boolean
  is_default?: boolean
}
export const SidebarMenu: React.FC<SidebarMenuProps> = observer(
  ({ activeRoute }) => {
    const history = useHistory()
    const alert = useAlert()
    const deleteReportModal = useToggle()
    const editReportNameModal = useToggle()
    const shareReportModal = useToggle()
    const {
      accountStore: { accountId },
      authStore: { user },
      creativeAnalyticsStore: {
        reportsList,
        reportToken,
        getReportsList,
        getReport,
        duplicateReport,
        cleanCurrentReport,
        cleanReportToken,
        deleteReport,
        updateReportName,
        shareReport,
        generateGuestReportToken,
      },
      analyticsFiltersStore: { setFiltersStatus },
    } = useStore()
    const [defaultReportsList, setDefaultReportsList] = React.useState<
      SidebarMenuListItem[]
    >([])
    const [userReportsList, setUserReportsList] = React.useState<
      SidebarMenuListItem[]
    >([])
    const [activeReport, setActiveReport] = React.useState<{
      id: number
      name: string
      is_read_only: boolean
      is_default: boolean
    } | null>(null)
    const [isActionsMenu, setIsActionsMenu] = React.useState(false)

    const ref = useOutsideClick(() => {
      setIsActionsMenu(false)
      setActiveReport(null)
    })

    useEffect(() => {
      if (accountId) getReportsList(accountId)
    }, [accountId])

    useEffect(() => {
      if (activeRoute && isValidReportString(activeRoute.value) && accountId) {
        const regex = /report\/(\d+)/
        const match = activeRoute.value.match(regex)
        const reportId = match ? Number(match[1]) : null
        if (reportId) getReport(accountId, reportId)
      }
    }, [activeRoute])

    useEffect(() => {
      const defaultReports: SidebarMenuListItem[] = reportsList
        .filter((report) => report.is_default)
        .map((r) => ({
          label: r.settings.name,
          value: `report/${r.id}`,
          before: <Icon name="file-list" />,
          id: r.id,
          is_read_only: r.is_read_only,
          is_default: r.is_default,
        }))
      const usersReports: SidebarMenuListItem[] = reportsList
        .filter((report) => !report.is_default)
        .map((r) => ({
          label: r.settings.name,
          value: `report/${r.id}`,
          before: <Icon name="file-list" />,
          id: r.id,
          is_read_only: r.is_read_only,
          is_default: r.is_default,
        }))
      setDefaultReportsList(defaultReports)
      setUserReportsList(usersReports)
    }, [reportsList])

    const handleMenu = async (value: string, id?: number) => {
      cleanReportToken()
      if (value === "dashboard" || value === "general" || value === "winners") {
        cleanCurrentReport()
        setFiltersStatus("base")
        if (accountId) history.replace(`/meta-ads/${accountId}/${value}`)
      } else if (accountId) {
        cleanCurrentReport()
        await getReport(accountId, id as number)
        history.replace(`/meta-ads/${accountId}/${value}`)
        setFiltersStatus("reportView")
      }
    }

    const handleDeleteReport = async () => {
      if (activeReport?.is_read_only) {
        alert.error("You can't delete this report because it is read-only")
        deleteReportModal.setOpened(false)
        return
      }
      deleteReportModal.setOpened(false)
      if (accountId)
        await deleteReport(accountId, activeReport?.id as number).then(
          (res) => {
            if (!res) {
              alert.success("Report successfully deleted")
              history.replace(`/meta-ads/${accountId}/general`)
              getReportsList(accountId)
            }
          }
        )
      setActiveReport(null)
    }

    const handleEditReportName = async (name: string) => {
      if (activeReport?.is_read_only) {
        alert.error("You can't edit this report because it is read-only")
        editReportNameModal.setOpened(false)
        return
      }
      editReportNameModal.setOpened(false)
      if (accountId)
        await updateReportName(
          accountId,
          activeReport?.id as number,
          name
        ).then((res) => {
          if (!res) {
            alert.success("Report name successfully updated")
            getReportsList(accountId)
          }
        })
      setActiveReport(null)
    }

    const handleDuplicateReport = () => {
      if (activeReport?.is_read_only) {
        alert.error("You can't duplicate this report because it is read-only")
      }
      if (accountId && activeReport)
        duplicateReport(accountId, activeReport?.id as number).then((res) => {
          if (!res) {
            alert.success("Report successfully duplicated")
            getReportsList(accountId)
          } else {
            alert.error("Report duplication failed")
          }
        })
    }

    const handleShareReport = (
      accountId: ID,
      reportId: ID,
      data: ReportShareEntity
    ) => {
      shareReport(accountId, reportId, data).then((res) => {
        if (res) alert.error(res)
        if (!res) alert.success("Report successfully shared")
      })
      shareReportModal.setOpened(false)
    }
    const handleGenerateGuestToken = (accountId: ID, reportID: ID) => {
      generateGuestReportToken(accountId, reportID).then((res) => {
        if (res) alert.error(res)
      })
    }

    return (
      <section className={styles.sidebarMenu}>
        <div>
          <Typography type="h2" className={styles.menuLabel}>
            Overview
          </Typography>
          <ul className={styles.sidebarList}>
            {personalSettingsOptions.map((report) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <li
                className={clsx(styles.sidebarListItem, {
                  [styles.sidebarListItemActive]:
                    activeRoute?.value === report.value,
                })}
                key={report.id}
                onClick={() => handleMenu(report.value)}
              >
                {report.before}
                <Typography
                  className={styles.sidebarListItemLabel}
                  type="body1"
                >
                  {report.label}
                </Typography>
              </li>
            ))}
          </ul>
        </div>

        <Divider noLine />

        <div className={styles.reportsSection}>
          <Typography type="h2" className={styles.menuLabel}>
            Reports
          </Typography>
          <ul className={styles.sidebarList}>
            {defaultReportsList.map((report) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <li
                className={clsx(styles.sidebarListItem, {
                  [styles.sidebarListItemActive]:
                    activeRoute?.value === report.value,
                })}
                key={report.id}
                onClick={() => handleMenu(report.value, report.id as number)}
              >
                {report.before}
                <Typography
                  className={styles.sidebarListItemLabel}
                  type="body1"
                >
                  {report.label}
                </Typography>
              </li>
            ))}
          </ul>

          <Divider />

          <ul className={styles.sidebarList}>
            {userReportsList.map((report) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <li
                className={clsx(
                  styles.sidebarListItem,
                  styles.sidebarListItemClickable,
                  {
                    [styles.sidebarListItemActive]:
                      activeRoute?.value === report.value,
                  }
                )}
                key={report.id}
                onClick={(event) => {
                  event.stopPropagation()
                  handleMenu(report.value, report.id as number)
                }}
              >
                <div className={styles.sidebarListItemInner}>
                  {report.before}
                  <Typography
                    className={clsx(styles.sidebarListItemLabel)}
                    type="body1"
                  >
                    {report.label}
                  </Typography>
                </div>
                {activeRoute?.value === report.value && (
                  <Icon
                    name="more-vertical"
                    className={clsx(styles.menuIcon)}
                    onClick={(event) => {
                      event.stopPropagation()
                      setIsActionsMenu(true)
                      setActiveReport({
                        id: report.id as number,
                        name: report.label,
                        is_read_only: report.is_read_only as boolean,
                        is_default: report.is_default as boolean,
                      })
                    }}
                  />
                )}

                {activeReport?.id === report.id && isActionsMenu && (
                  <ul
                    ref={ref}
                    className={clsx(styles.sidebarList, styles.actionsMenu)}
                  >
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <li
                      className={styles.sidebarListItem}
                      onClick={(event) => {
                        event.stopPropagation()
                        setIsActionsMenu(false)
                        editReportNameModal.setOpened(true)
                      }}
                    >
                      <Icon name="pen" />
                      <Typography
                        className={styles.sidebarListItemLabel}
                        type="body2"
                      >
                        Rename
                      </Typography>
                    </li>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <li
                      className={styles.sidebarListItem}
                      onClick={(event) => {
                        event.stopPropagation()
                        setIsActionsMenu(false)
                        handleDuplicateReport()
                      }}
                    >
                      <Icon name="file-copy" />
                      <Typography
                        className={styles.sidebarListItemLabel}
                        type="body2"
                      >
                        Duplicate
                      </Typography>
                    </li>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <li
                      className={styles.sidebarListItem}
                      onClick={(event) => {
                        event.stopPropagation()
                        setIsActionsMenu(false)
                        shareReportModal.setOpened(true)
                      }}
                    >
                      <Icon name="link" />
                      <Typography
                        className={styles.sidebarListItemLabel}
                        type="body2"
                      >
                        Share report
                      </Typography>
                    </li>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <li
                      className={styles.sidebarListItem}
                      onClick={(event) => {
                        event.stopPropagation()
                        setIsActionsMenu(false)
                        deleteReportModal.setOpened(true)
                      }}
                    >
                      <Icon name="trash" />
                      <Typography
                        className={styles.sidebarListItemLabel}
                        type="body2"
                      >
                        Delete
                      </Typography>
                    </li>
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>

        <DeleteReportModal
          reportName={activeReport?.name as string}
          isOpen={deleteReportModal.isOpened}
          onClose={() => {
            deleteReportModal.setOpened(false)
            setActiveReport(null)
            setIsActionsMenu(false)
          }}
          onDeleteReport={handleDeleteReport}
        />

        <EditReportNameModal
          reportName={activeReport?.name as string}
          isOpen={editReportNameModal.isOpened}
          onClose={() => {
            editReportNameModal.setOpened(false)
            setActiveReport(null)
            setIsActionsMenu(false)
          }}
          onEditReport={handleEditReportName}
        />

        <ShareReportModal
          isOpen={shareReportModal.isOpened}
          onClose={() => {
            shareReportModal.setOpened(false)
            setActiveReport(null)
            setIsActionsMenu(false)
          }}
          reportToken={reportToken}
          reportId={activeReport?.id as number}
          accountId={accountId}
          onShareReport={handleShareReport}
          onGenerateGuestToken={handleGenerateGuestToken}
        />
      </section>
    )
  }
)

export default SidebarMenu
