import React, { useCallback, useEffect, useMemo, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useStore } from "@store/index"
import Stack from "@components/ui/Stack/Stack"
import { metricsDescriptors } from "@framework/constants/metrics"
import useToggle from "@framework/hooks/useToggle"
import Loader from "@components/ui/Loader/Loader"
import {
  AnalyticsCard,
  AnalyticsFilter,
  filterEqualityMapper,
  filterIncludesTypes,
} from "@framework/types/creativeAnalytics"
import {
  MetricsTrendType,
  MetricDescription,
  MetricType,
} from "@framework/types/metrics"
import KPITrendModal from "@pages/MetaAdsChannel/components/KPITrendChart/KPITrendModal"
import { apiDateFormatter } from "@services/utils"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import { calculateMetricsTrend } from "@pages/MetaAdsChannel/components/utils"
import Icon from "@components/ui/Icon/Icon"
import Box from "@components/ui/Box/Box"
import Chip from "@components/ui/Chip/Chip"
import Typography from "@components/ui/Typography/Typography"
import ReportGroup from "@pages/MetaAdsChannel/components/ReportCard/ReportGroup"
import without from "lodash/without"
import ReportSummaryPreview from "@pages/MetaAdsChannel/components/ReportCard/ReportSummaryPreview"
import ReportSummaryCard from "@pages/MetaAdsChannel/components/ReportCard/ReportSummaryCard"
import {
  ADSAnalyticsGroupTypeEntity,
  ADSAnalyticsResponse,
} from "@services/creative-analytics"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import VideoPreviewModal from "@pages/MetaAdsChannel/components/Modals/VIdeoPreviewModal"
import ReportControlPanelStatic from "@pages/MetaAdsChannel/AnalyticsReportStaticPage/ReportControlPanelStatic"
import mapper from "@pages/MetaAdsChannel/General/mapper"
import { useLocation } from "react-router-dom"
import ReportCard from "../components/ReportCard/ReportCard"
import { MetaCampaignStatus, ViewType } from "../types"
import Table from "../components/Table/Table"
import ReportDetailsModal from "../components/ReportDetails/ReportDetails"

import styles from "./AnalyticsReportStaticPage.module.scss"

const AnalyticsReportStaticPage: React.FC = observer(() => {
  const {
    analyticsFiltersStore: {
      activePeriodicity,
      allFilters,
      setActivePeriodicity,
      setAllFilters,
    },
    creativeAnalyticsStore: {
      adsCardsData,
      KPITrendsData,
      reportData,
      getKPITrends,
      getReport,
      isLoading,
      isKPILoading,
    },
  } = useStore()
  const [reportUrlParams, setReportUrlParams] = useState<{
    urlAccountId: number
    urlReportId: number
    guestToken: string | null
  }>({
    urlAccountId: 0,
    urlReportId: 0,
    guestToken: null,
  })

  const location = useLocation()

  const {
    view,
    filters,
    groupingType,
    sortByMetric,
    period,
    sortColorMetrics,
  } = allFilters

  const [filteredCards, setFilteredCards] = React.useState<AnalyticsCard[]>(
    adsCardsData.AdCreatives
  )
  const [filteredGroups, setFilteredGroups] = useState<
    ADSAnalyticsResponse["data"]["Groupings"]
  >(adsCardsData.Groupings)

  const [activeMetrics, setActiveMetrics] = useState<MetricDescription[]>([])
  const [metricsTrend, setMetricsTrend] = useState<MetricsTrendType>({})
  const [report, setReport] = useState<{
    id: string
    name: string
    status: MetaCampaignStatus
  } | null>(null)
  const [reportDetails, setReportDetails] = useState<AnalyticsCard>()
  const [activeGroup, setActiveGroup] = React.useState<string | undefined>()
  const [groupListViewOptions, setGroupListViewOptions] = useState<{
    active: ADSAnalyticsGroupTypeEntity | null
    before: ADSAnalyticsGroupTypeEntity[]
    after: ADSAnalyticsGroupTypeEntity[]
  }>({
    active: null,
    before: [],
    after: [],
  })

  const modal = useToggle()
  const KPImodal = useToggle()
  const videoPreviewModal = useToggle()

  const onAppliedFilters = (filters: AnalyticsFilter[][]) => {
    if (filters.length === 0) {
      setFilteredCards(adsCardsData.AdCreatives)
      setFilteredGroups(adsCardsData.Groupings)
      setAllFilters({ ...allFilters, filters: [] })
      return
    }
    setAllFilters({ ...allFilters, filters })
  }

  const onMetricsFilterChange = (metrics: string[]) => {
    const filteredMetricsDescriptors = metricsDescriptors.filter((md) =>
      metrics.includes(md.name)
    )
    setActiveMetrics(filteredMetricsDescriptors)
  }

  const handleOpenKPItrendModal = (
    id: string,
    name: string,
    status: MetaCampaignStatus
  ) => {
    setReport({ id, name, status: status || "INACTIVE" })
    KPImodal.setOpened(true)
  }

  const handleOpenReportDetailsModal = (card: AnalyticsCard) => {
    setReportDetails(card)
    modal.handleToggle()
  }

  const handleActivePeriodicity = (
    periodicity: PerformanceReportPeriodicity
  ) => {
    setActivePeriodicity(periodicity)
  }

  const handleOpenVideoPreview = (card: AnalyticsCard) => {
    setReportDetails(card)
    videoPreviewModal.setOpened(true)
  }

  const handlePreviousDetailsCard = (cardId: string) => {
    if (groupingType === "None") {
      const currentIndex = filteredCards.findIndex(
        (it: AnalyticsCard) => it.Id === cardId
      )
      let prevoriusCard: AnalyticsCard = filteredCards[currentIndex - 1]
      if (currentIndex === 0) {
        prevoriusCard = filteredCards[filteredCards.length - 1]
      }
      setReportDetails(prevoriusCard)
    } else {
      const cardsFilteredByCurrentActiveGroup = filteredCards.filter((card) =>
        groupListViewOptions.active?.Ads.includes(card.Id)
      )
      if (cardsFilteredByCurrentActiveGroup.length === 1) return
      const currentIndex = cardsFilteredByCurrentActiveGroup.findIndex(
        (it: AnalyticsCard) => it.Id === cardId
      )
      let prevoriusCard: AnalyticsCard =
        cardsFilteredByCurrentActiveGroup[currentIndex - 1]
      if (currentIndex === 0) {
        prevoriusCard =
          cardsFilteredByCurrentActiveGroup[
            cardsFilteredByCurrentActiveGroup.length - 1
          ]
      }
      setReportDetails(prevoriusCard)
    }
  }

  const handleNextDetailsCard = (cardId: string) => {
    if (groupingType === "None") {
      const currentIndex = filteredCards.findIndex(
        (it: AnalyticsCard) => it.Id === cardId
      )
      let nextCard: AnalyticsCard = filteredCards[currentIndex + 1]
      if (currentIndex === filteredCards.length - 1) {
        // eslint-disable-next-line prefer-destructuring
        nextCard = filteredCards[0]
      }
      setReportDetails(nextCard)
    } else {
      const cardsFilteredByCurrentActiveGroup = filteredCards.filter((card) =>
        groupListViewOptions.active?.Ads.includes(card.Id)
      )
      if (cardsFilteredByCurrentActiveGroup.length === 1) return
      const currentIndex = cardsFilteredByCurrentActiveGroup.findIndex(
        (it: AnalyticsCard) => it.Id === cardId
      )
      let nextCard: AnalyticsCard =
        cardsFilteredByCurrentActiveGroup[currentIndex + 1]
      if (currentIndex === cardsFilteredByCurrentActiveGroup.length - 1) {
        // eslint-disable-next-line prefer-destructuring
        nextCard = cardsFilteredByCurrentActiveGroup[0]
      }
      setReportDetails(nextCard)
    }
  }

  const calculateTotalCardsWithinActiveGroup: number = useMemo(() => {
    const cards = filteredCards.filter((card) =>
      groupListViewOptions.active?.Ads.includes(card.Id)
    )
    return cards?.length || 0
  }, [filteredCards, groupListViewOptions])

  const calculateTotalCardsWithinGroup = useCallback(
    (group: ADSAnalyticsGroupTypeEntity) => {
      const cards = filteredCards.filter((card) => group.Ads.includes(card.Id))
      return cards?.length || 0
    },
    [filteredCards]
  )

  useEffect(() => {
    const regex = /\/meta\/(\d+)\/static-report\/(\d+)\?token=([a-f0-9]+)/
    const url = `${location.pathname}${location.search}`
    const match = url.match(regex)
    if (match) {
      setReportUrlParams({
        urlAccountId: Number(match[1]) ?? 0,
        urlReportId: Number(match[2]) ?? 0,
        guestToken: match[3] ?? null,
      })
    }
  }, [])

  useEffect(() => {
    if (reportData && reportData.id !== 0 && adsCardsData) {
      let newPeriod: DateRangeOption = {
        label: "",
        value: "",
        range: period.range,
      }
      if (
        reportData?.settings.time_period.start_date &&
        reportData?.settings.time_period.end_date
      ) {
        newPeriod = {
          label: reportData?.settings.time_period.range_type,
          value: reportData?.settings.time_period.range_value,
          range: [
            new Date(reportData.settings.time_period.start_date),
            new Date(reportData.settings.time_period.end_date),
          ],
        }
      }
      if (reportData?.settings.metrics.length) {
        const appliedMetrics = metricsDescriptors.filter((md) =>
          reportData?.settings.metrics.includes(md.name)
        )
        setActiveMetrics(appliedMetrics)
      }
      let filtersArray: AnalyticsFilter[][] = []
      if (reportData?.settings?.filters?.length) {
        filtersArray = reportData?.settings.filters.map((f) => [
          { key: 1, value: f.name },
          { key: 2, value: f.comparator },
          { key: 3, value: f.value },
        ])
      }
      setAllFilters({
        view:
          (reportData?.settings.view_mode.toLowerCase() as ViewType) || "card",
        filters: filtersArray,
        groupingType: reportData?.settings.group
          ? reportData.settings.group
          : "None",
        sortByMetric: reportData?.settings?.sort_option?.type
          ? {
              value: reportData.settings.sort_option.type,
              order: reportData.settings.sort_option.order,
            }
          : null,
        period: newPeriod,
        sortColorMetrics: reportData?.settings?.color_sort_options || [],
      })
    }
  }, [reportData, adsCardsData])

  useEffect(() => {
    const res = calculateMetricsTrend(
      adsCardsData.AdCreatives,
      sortColorMetrics
    )
    setMetricsTrend(res)
  }, [adsCardsData, sortColorMetrics])

  useEffect(() => {
    if (reportUrlParams.guestToken)
      getReport(reportUrlParams.urlAccountId, reportUrlParams.urlReportId, {
        token: reportUrlParams.guestToken,
      })
  }, [reportUrlParams])

  useEffect(() => {
    setFilteredCards(adsCardsData.AdCreatives)
    setFilteredGroups(adsCardsData.Groupings)
  }, [adsCardsData])

  useEffect(() => {
    if (!report) return
    if (reportUrlParams.guestToken) {
      getKPITrends(reportUrlParams.urlAccountId, report.id, {
        from: apiDateFormatter(period.range[0]),
        to: apiDateFormatter(period.range[1]),
        periodicity: activePeriodicity,
        token: reportUrlParams.guestToken,
      })
    }
  }, [report, allFilters, reportData])

  useEffect(() => {
    let result: AnalyticsCard[] = adsCardsData.AdCreatives
    let resultGroups =
      groupingType !== "None" && adsCardsData.Groupings[groupingType]
        ? adsCardsData.Groupings[groupingType]
        : []
    if (filters.length) {
      filters.forEach((filter) => {
        const firstFilter = filter[0]
        const secondFilter = filter[1]
        if (
          firstFilter.value === "campaigns" ||
          firstFilter.value === "adSets" ||
          firstFilter.value === "creativeType"
        ) {
          const thirdFilters = filter.filter((f) => f.key === 3)
          if (filterIncludesTypes[secondFilter.value] === "Includes") {
            result = result.filter((card) => {
              if (firstFilter.value === "campaigns")
                return thirdFilters.find((f) => f.value === card.CampaignName)
              if (firstFilter.value === "adSets")
                return thirdFilters.find((f) => f.value === card.AdsetName)
              return thirdFilters.find((f) => f.value === card.Type)
            })
          }
          if (filterIncludesTypes[secondFilter.value] === "Does not include") {
            result = result.filter((card) => {
              if (firstFilter.value === "campaigns")
                return !thirdFilters.find((f) => f.value === card.CampaignName)
              if (firstFilter.value === "adSets")
                return !thirdFilters.find((f) => f.value === card.AdsetName)
              return !thirdFilters.find((f) => f.value === card.Type)
            })
          }
        } else {
          const firstFilterValue = firstFilter.value
          const secondFilterValue = secondFilter.value
          const thirdFilterValue = Number(filter[2].value)
          const filterEqualityMapperType =
            filterEqualityMapper(secondFilterValue)
          result = result.filter((card) => {
            const metric = card[firstFilterValue]
            if (typeof metric === "object") {
              return filterEqualityMapperType(metric.Value, thirdFilterValue)
            }
            return false
          })
          resultGroups = resultGroups.filter((card) => {
            // @ts-ignore
            const metric = card.Performance[firstFilterValue]
            if (typeof metric === "object") {
              return filterEqualityMapperType(metric.Value, thirdFilterValue)
            }
            return false
          })
        }
      })
    }
    if (sortByMetric?.value && sortByMetric?.order) {
      const cards = [...result]
      const groups: ADSAnalyticsGroupTypeEntity[] =
        groupingType !== "None" && filteredGroups[groupingType]
          ? [...resultGroups]
          : []
      cards.sort((a, b) => {
        const aMetric = a[sortByMetric.value] as MetricType
        const bMetric = b[sortByMetric.value] as MetricType
        const order = sortByMetric.order === "asc" ? 1 : -1
        return (aMetric.Value - bMetric.Value) * order
      })

      groups.sort((a, b) => {
        // @ts-ignore
        const aMetric = a.Performance?.[sortByMetric.value] as MetricType
        // @ts-ignore
        const bMetric = b.Performance?.[sortByMetric.value] as MetricType
        const order = sortByMetric.order === "asc" ? 1 : -1
        return (aMetric.Value - bMetric.Value) * order
      })

      setFilteredGroups((prevState) => ({
        ...prevState,
        [groupingType]: groups,
      }))
      setFilteredCards(cards)
    } else {
      setFilteredCards(result)
      if (groupingType === "None") {
        setFilteredGroups(adsCardsData.Groupings)
      } else {
        setFilteredGroups((prevState) => ({
          ...prevState,
          [groupingType]: resultGroups,
        }))
      }
    }
  }, [adsCardsData, reportData, allFilters])

  useEffect(() => {
    if (filteredGroups) {
      let activeIndex = -1

      if (groupingType !== "None" && filteredGroups[groupingType]) {
        activeIndex = filteredGroups[groupingType]?.findIndex(
          (it) => it.Name === activeGroup
        )
        const active = filteredGroups[groupingType][activeIndex]
        const rest = without(filteredGroups[groupingType], active)
        const midIndex = Math.round(activeIndex / 3) * 3

        const before = rest.slice(0, midIndex)
        const after = rest.slice(midIndex)
        setGroupListViewOptions({
          active,
          before,
          after,
        })
      }
      if (activeIndex === -1 || groupingType === "None") {
        setGroupListViewOptions({
          active: null,
          before: [],
          after: [],
        })
      }
    }
  }, [filteredGroups, activeGroup, allFilters, reportData])

  if (
    (reportUrlParams.urlReportId === 0 && reportUrlParams.urlAccountId === 0) ||
    reportUrlParams.guestToken === null ||
    (!reportData && !isLoading) ||
    (reportData?.id === 0 && !isLoading)
  ) {
    return (
      <section className={styles.wrongReportPage}>
        <Typography type="h1" weight="bold" color="error120Color">
          Wrong report link. Please, check the URL and try again
        </Typography>
      </section>
    )
  }

  const renderGroup = (group: ADSAnalyticsGroupTypeEntity, index: number) => (
    <ReportGroup
      title={group.Name}
      titleOverflow
      total={calculateTotalCardsWithinGroup(group)}
      onToggleClick={(event) => setActiveGroup(group.Name)}
    >
      <ReportSummaryPreview>
        <ReportSummaryCard
          data={group.Performance}
          metricsTrend={metricsTrend}
          metrics={activeMetrics}
        />
      </ReportSummaryPreview>
    </ReportGroup>
  )

  const renderReport = (report: AnalyticsCard) => (
    <ReportCard
      data={report}
      metricsTrend={metricsTrend}
      metrics={activeMetrics}
      onClick={(event) => {
        event.stopPropagation()
        handleOpenReportDetailsModal(report)
      }}
      onOpenKPIChart={handleOpenKPItrendModal}
      onOpenVideoPreview={handleOpenVideoPreview}
      key={report.Id}
    />
  )

  return (
    <article>
      <Stack direction="column" gutter="20" align="stretch">
        <ReportControlPanelStatic
          onAppliedFilters={onAppliedFilters}
          onMetricsFilter={onMetricsFilterChange}
          accountId={reportUrlParams.urlAccountId}
          token={reportUrlParams.guestToken}
        />

        {!isLoading && (
          <Box>
            <Typography type="body2" weight="bold">
              Color sorting by
            </Typography>
            <div className={styles.colorMetricsPanel}>
              {sortColorMetrics.map((filter) => {
                if (!filter) return null
                return (
                  <Chip
                    type="contained"
                    color="secondary"
                    endIcon={<Icon name="cross" />}
                    className={styles.filterChip}
                    key={filter}
                  >
                    {filter}
                  </Chip>
                )
              })}
            </div>
          </Box>
        )}

        {isLoading && <Loader />}

        {view === "table" && !isLoading && (
          <Table
            className={styles.table}
            mapper={Object.values(mapper(activeMetrics, metricsTrend))}
            data={filteredCards}
            summary={adsCardsData.Summary}
            metricsTrend={metricsTrend}
            onRowClick={handleOpenReportDetailsModal}
          />
        )}

        {view === "card" && groupingType === "None" && !isLoading && (
          <div className={styles.grid}>
            {filteredCards.map((card) => (
              <ReportCard
                className={styles.reportCardStaticView}
                onOpenVideoPreview={handleOpenVideoPreview}
                data={card}
                metricsTrend={metricsTrend}
                metrics={activeMetrics}
                onClick={(event) => {
                  event.stopPropagation()
                  handleOpenReportDetailsModal(card)
                }}
                onOpenKPIChart={handleOpenKPItrendModal}
                key={card.Id}
              />
            ))}
          </div>
        )}

        <div className={styles.gridGroups}>
          {groupListViewOptions.active &&
          !isLoading &&
          filteredGroups &&
          groupingType !== "None" &&
          view !== "table" ? (
            <>
              {groupListViewOptions.before.map(renderGroup)}

              <ReportGroup
                className={clsx(styles.fullWidth)}
                title={groupListViewOptions.active?.Name}
                total={calculateTotalCardsWithinActiveGroup}
                showAll
                onToggleClick={(event) => setActiveGroup(undefined)}
              >
                <div className={styles.goalsGrid}>
                  {filteredCards
                    .filter((card) =>
                      groupListViewOptions.active?.Ads.includes(card.Id)
                    )
                    .map(renderReport)}
                </div>
              </ReportGroup>

              {groupListViewOptions.after.map(renderGroup)}
            </>
          ) : !groupListViewOptions.active &&
            !isLoading &&
            groupingType !== "None" &&
            filteredGroups[groupingType] &&
            view !== "table" ? (
            <>
              {filteredGroups && filteredGroups[groupingType].map(renderGroup)}
            </>
          ) : null}
        </div>
      </Stack>

      <ReportDetailsModal
        open={modal.isOpened}
        onClose={() => modal.setOpened(false)}
        reportDetails={reportDetails as AnalyticsCard}
        metricsTrend={metricsTrend}
        externalReportAccountId={reportUrlParams.urlAccountId}
        guestToken={reportUrlParams.guestToken}
        onPreviousClick={handlePreviousDetailsCard}
        onNextClick={handleNextDetailsCard}
      />

      <KPITrendModal
        activePeriodicity={activePeriodicity}
        onActivePeriodicity={handleActivePeriodicity}
        report={report}
        data={KPITrendsData}
        open={KPImodal.isOpened}
        isKPILoading={isKPILoading}
        onClose={() => KPImodal.setOpened(false)}
      />

      {videoPreviewModal.isOpened && reportDetails ? (
        <VideoPreviewModal
          data={reportDetails}
          onDetailsOpen={() => {
            modal.setOpened(true)
            videoPreviewModal.setOpened(false)
          }}
          onClose={() => videoPreviewModal.setOpened(false)}
        />
      ) : null}
    </article>
  )
})

export default AnalyticsReportStaticPage
