import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import LineChart, {
  LineDataType,
} from "@components/ui/Charts/LineChart/LineChart"
import Box from "@components/ui/Box/Box"
import { darkColors } from "@components/ui/Charts/utils"
import makePerformanceChartConfig, {
  makePerformanceChartYAxisConfig,
} from "@components/ui/Charts/LineChart/config"

import Templates from "@components/ui/Templates"
import Typography from "@components/ui/Typography/Typography"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import { renderMetricOption } from "@framework/constants/metrics"
import { MetricNameType, PerformanceReport } from "@framework/types/metrics"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import Tabs from "@components/ui/Tabs/Tabs"
import { periodicityOptions } from "@framework/constants/report"
import { KPITrendsResponse } from "@services/creative-analytics"
import { defaultMetrics } from "@framework/types/creativeAnalytics"
import { extractPlatformReportDataset } from "./utils"

import styles from "./index.module.sass"

type KPITrendChartProps = {
  data: KPITrendsResponse["data"]
  onActivePeriodicity: (periodicity: PerformanceReportPeriodicity) => void
  activePeriodicity: PerformanceReportPeriodicity
  isKPILoading: boolean
  className?: string
}

const KPITrendChart: React.FC<KPITrendChartProps> = observer(
  ({
    data,
    activePeriodicity,
    onActivePeriodicity,
    isKPILoading,
    className,
  }) => {
    const [chartData, setChartData] = React.useState<
      Array<[string, PerformanceReport[]]>
    >([])

    const [activeMetric, setActiveMetric] = React.useState<MetricNameType>(
      defaultMetrics[0] as MetricNameType
    )

    useEffect(() => {
      setChartData(Object.entries(data))
    }, [data])

    const labels = React.useMemo(
      () => chartData.map(([key, value]) => key),
      [activePeriodicity, chartData]
    )

    const generateChartData: LineDataType[] = React.useMemo(
      () => [
        {
          dashed: false,
          dataset: [
            extractPlatformReportDataset(
              activeMetric,
              // @ts-ignore
              chartData.map(([key, value]) => value[0][activeMetric].Value)
            ),
          ],
        },
      ],
      [activeMetric, activePeriodicity, chartData]
    )

    const chartConfig = React.useMemo(
      () =>
        makePerformanceChartConfig(
          labels,
          activePeriodicity,
          {
            y: makePerformanceChartYAxisConfig(activeMetric, "left"),
          },
          "unit"
        ),
      [labels, activePeriodicity, activeMetric, chartData]
    )

    return (
      <Box className={clsx(styles.root, className)}>
        {/* Main header */}
        <Templates.TripleHeader
          // offset="medium"
          left={<Typography type="h2">KPI Trends</Typography>}
          right={
            <>
              <SimpleSelect
                className={styles.filed}
                value={activeMetric}
                options={defaultMetrics}
                renderValue={renderMetricOption}
                onSelect={(v) => setActiveMetric(v as MetricNameType)}
              />
            </>
          }
        >
          <Tabs
            options={periodicityOptions}
            currentValue={activePeriodicity}
            onChange={({ value }) =>
              onActivePeriodicity(value as PerformanceReportPeriodicity)
            }
          />
        </Templates.TripleHeader>

        <LineChart
          isLoading={isKPILoading}
          lineData={generateChartData}
          labels={labels}
          colors={darkColors}
          options={chartConfig}
        />
      </Box>
    )
  }
)

export default KPITrendChart
