import React, { useState, useEffect } from "react"
import { ScrollSync } from "react-scroll-sync"
import clsx from "clsx"
import AutoSizer from "react-virtualized-auto-sizer"
import InfiniteLoader from "react-window-infinite-loader"
import { observer } from "mobx-react-lite"
import { List, ListRowProps } from "react-virtualized"

import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import { ADSAnalyticsResponse } from "@services/creative-analytics"
import { MetricsTrendType, MetricType } from "@framework/types/metrics"
import { useStore } from "@root/store"
import Header from "./Header"
import Row from "./Row"
import { ColumnMapper } from "./types"
import Footer from "./Footer"

import styles from "./Table.module.scss"

const SINGLE_ROW_HEIGHT = 48

const STATIC_BEFORE = 1

export interface SortState {
  column: string
  direction: "asc" | "desc"
}

interface TableProps<T extends object> {
  className?: string
  mapper: ColumnMapper<T>[]
  data: AnalyticsCard[]
  metricsTrend: MetricsTrendType
  summary: ADSAnalyticsResponse["data"]["Summary"]
  lastUpdate?: number
  onRowClick?: (item: AnalyticsCard) => void
}

const Table = observer(
  <T extends object>({
    className,
    mapper,
    data,
    summary,
    lastUpdate,
    metricsTrend,
    onRowClick,
  }: TableProps<T>) => {
    const {
      analyticsFiltersStore: { allFilters },
    } = useStore()
    const infiniteLoaderRef = React.useRef<InfiniteLoader>(null)
    const hasMountedRef = React.useRef(false)
    const [sortState, setSortState] = useState<SortState>({
      column: "",
      direction: "asc",
    })
    const [sortedData, setSortedData] = useState<AnalyticsCard[]>([])

    const handleLoadMore = async (startIndex: number, stopIndex: number) => {
      // TODO add loader if needed
    }

    const isRowLoaded = (idx: number) => !!sortedData?.[idx]

    const renderRow = ({ index, style, key }: ListRowProps) => {
      const item = sortedData?.[index]
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          key={key}
          style={style}
          className={styles.row}
          onClick={() => onRowClick && onRowClick(item)}
        >
          <Row
            metricsTrend={metricsTrend}
            data={item}
            mapper={mapper}
            staticBefore={STATIC_BEFORE}
          />
        </div>
      )
    }

    const handleSort = (column: string) => {
      setSortState((prevState) => {
        const isSameColumn = prevState.column === column
        const nextDirection =
          isSameColumn && prevState.direction === "asc" ? "desc" : "asc"
        return { column, direction: nextDirection }
      })
    }

    useEffect(() => {
      if (hasMountedRef.current && infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache(true)
      }
      hasMountedRef.current = true
    }, [lastUpdate])

    useEffect(() => {
      let res: AnalyticsCard[] = []
      if (!sortState.column && !data) return
      if (!sortState.column && data) res = [...data]
      if (sortState.column && data) {
        res = [...data].sort((a, b) => {
          const aMetric = a[sortState.column] as MetricType
          const bMetric = b[sortState.column] as MetricType
          if (aMetric.Value < bMetric.Value)
            return sortState.direction === "asc" ? -1 : 1
          if (aMetric.Value > bMetric.Value)
            return sortState.direction === "asc" ? 1 : -1
          return 0
        })
      }
      setSortedData(res)
    }, [sortState, data])

    return (
      <ScrollSync>
        <div className={clsx(styles.root, className)}>
          <Header
            mapper={mapper}
            staticBefore={STATIC_BEFORE}
            className={styles.headerContainer}
            sortState={sortState}
            onSort={handleSort}
          />
          {summary != null &&
            allFilters.filters.length === 0 &&
            allFilters.sortByMetric === null && (
              <Footer
                mapper={mapper}
                staticBefore={STATIC_BEFORE}
                className={styles.headerContainer}
                data={summary}
              />
            )}

          <div className={clsx(styles.body)}>
            <InfiniteLoader
              ref={infiniteLoaderRef}
              isItemLoaded={isRowLoaded}
              itemCount={sortedData.length}
              loadMoreItems={handleLoadMore}
              threshold={40}
              minimumBatchSize={40}
            >
              {({ ref }) => (
                <AutoSizer>
                  {(size) => (
                    <List
                      className={styles.list}
                      overscanCount={3}
                      height={size.height}
                      width={size.width}
                      rowCount={sortedData.length}
                      rowHeight={SINGLE_ROW_HEIGHT}
                      rowRenderer={renderRow}
                      ref={ref}
                    />
                  )}
                </AutoSizer>
              )}
            </InfiniteLoader>
          </div>
        </div>
      </ScrollSync>
    )
  }
)

export default Table
