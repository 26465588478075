import React, { FC } from "react"
import Modal from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Typography from "@components/ui/Typography/Typography"
import styles from "./Modals.module.scss"

interface CreateReportModalProps {
  reportName: string
  isOpen: boolean
  onClose: () => void
  onEditReport: () => void
}

const EditReportModal: FC<CreateReportModalProps> = ({
  reportName,
  onClose,
  isOpen,
  onEditReport,
}) => {
  const handleSaveReport = () => {
    onEditReport()
  }
  return (
    <Modal
      title={`Save changes to the ${reportName}`}
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modalBase}
    >
      <Stack align="stretch">
        <Stack direction="column" justify="space-between" align="stretch">
          <Typography type="body1">
            Are you sure you want to save changes made in the report?
          </Typography>
        </Stack>
        <Stack direction="row" justify="flex-end">
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSaveReport}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default EditReportModal
