import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Modal from "@components/ui/Modal/Modal"
import Icon from "@components/ui/Icon/Icon"
import Templates from "@components/ui/Templates"
import IconButton from "@components/ui/Button/IconButton"
import { KPITrendsResponse } from "@services/creative-analytics"
import { MetaCampaignStatus } from "@pages/MetaAdsChannel/types"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import StatusBadge from "@pages/MetaAdsChannel/components/StatusBadge"
import ReportTitle from "../ReportTitle"
import KPITrendChart from "./KPITrendChart"

import styles from "./KPITrendModal.module.sass"

interface KPITrendModalProps {
  open: boolean
  isKPILoading: boolean
  onActivePeriodicity: (periodicity: PerformanceReportPeriodicity) => void
  activePeriodicity: PerformanceReportPeriodicity
  report: {
    id: string
    name: string
    status: MetaCampaignStatus
  } | null
  data: KPITrendsResponse["data"]
  onClose: () => void
}

export const KPITrendModal: React.FC<KPITrendModalProps> = observer(
  ({
    open,
    report,
    onActivePeriodicity,
    activePeriodicity,
    data,
    isKPILoading,
    onClose,
  }) => (
    <Modal
      className={clsx(styles.root)}
      isOpen={open}
      onClose={onClose}
      hideHeader
    >
      <div className={styles.header}>
        <Templates.TripleHeader
          left={
            <div className={styles.title}>
              <ReportTitle>{report?.name}</ReportTitle>
              <StatusBadge status={report?.status} short />
            </div>
          }
          right={
            <>
              <IconButton
                variant="contained"
                color="secondary"
                onClick={onClose}
              >
                <Icon name="cross" />
              </IconButton>
            </>
          }
        />
      </div>

      <div className={clsx(styles.body)}>
        <KPITrendChart
          data={data}
          isKPILoading={isKPILoading}
          activePeriodicity={activePeriodicity}
          onActivePeriodicity={onActivePeriodicity}
        />
      </div>
    </Modal>
  )
)

export default KPITrendModal
