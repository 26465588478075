import React from "react"

interface PlayIconProps {
  width?: number
  height?: number
}

const PlayIcon = ({ width, height }: PlayIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 50}
    height={height || 50}
  >
    <circle cx={25} cy={25} r={22} fill="#fff" stroke="#fff" strokeWidth={2} />
    <path d="m22 15 7 10-7 10Z" />
  </svg>
)
export default PlayIcon
