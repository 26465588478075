import React from "react"
import { observer } from "mobx-react-lite"

import {
  periodLength,
  printDateRangeWithDiff,
  printOnlyDateRange,
} from "@utils/date"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import DatePeriodOption from "@components/ui/DatePicker/DatePeriodOption"
import Select from "@components/ui/DropDown"
import { mbNoData } from "@services/utils"
import Typography from "@components/ui/Typography/Typography"
import { Button } from "../Button"
import Icon from "../Icon/Icon"

import styles from "./ButtonDatePicker.module.scss"

export interface ButtonDatePickerProps {
  maxDate?: Date
  minDate?: Date
  range: DateRangeOption
  rangeOptions: DateRangeOption[]
  showDays?: boolean
  onRangeChange?: (option: DateRangeOption) => void
}
export const ButtonDatePicker: React.FC<ButtonDatePickerProps> = observer(
  ({
    range: currPeriod,
    rangeOptions,
    minDate,
    maxDate,
    showDays,
    onRangeChange,
  }) => {
    const countDays: string =
      periodLength(currPeriod.range, "day") === 1
        ? `${periodLength(currPeriod.range, "day")} day`
        : `${periodLength(currPeriod.range, "day")} days`
    return (
      <Select.DropDown
        keepOpened
        target={(isActive) => (
          <div className={styles.button}>
            <Button
              before={<Icon name="calendar" />}
              color="secondary"
              variant={isActive ? "outlined" : "contained"}
            >
              {currPeriod?.range && showDays
                ? countDays
                : currPeriod?.range && !showDays
                ? printOnlyDateRange(currPeriod.range)
                : "Select date..."}
            </Button>
            {currPeriod?.range && showDays ? (
              <Typography type="caption2" className={styles.tooltip}>
                {printOnlyDateRange(currPeriod.range)}
              </Typography>
            ) : currPeriod?.range && !showDays ? (
              <Typography type="caption2" className={styles.tooltip}>
                {countDays}
              </Typography>
            ) : (
              ""
            )}
          </div>
        )}
      >
        <Select.Box className={styles.box}>
          <Select.Group>
            <DatePeriodOption
              title="Date range"
              subtitle={
                currPeriod ? printDateRangeWithDiff(currPeriod.range) : "-"
              }
              value={currPeriod?.value}
              onSelect={(v) => onRangeChange?.(v)}
              options={rangeOptions}
              minDate={minDate}
              maxDate={maxDate}
            >
              {mbNoData(currPeriod?.label)}
            </DatePeriodOption>
          </Select.Group>
        </Select.Box>
      </Select.DropDown>
    )
  }
)

export default ButtonDatePicker
