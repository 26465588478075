import React, { FC } from "react"
import Modal from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Typography from "@components/ui/Typography/Typography"
import { observer } from "mobx-react-lite"
import styles from "./Modals.module.scss"

interface DeleteReportModalProps {
  reportName: string
  isOpen: boolean
  onClose: () => void
  onDeleteReport: () => void
}

const DeleteReportModal: FC<DeleteReportModalProps> = observer(
  ({ reportName, onClose, isOpen, onDeleteReport }) => {
    const handleDeleteReport = () => {
      onDeleteReport()
    }
    return (
      <Modal
        title={`Delete ${reportName}`}
        isOpen={isOpen}
        onClose={onClose}
        className={styles.modalBase}
      >
        <Stack align="stretch">
          <Stack direction="column" justify="space-between" align="stretch">
            <Typography type="body1">
              Are you sure you want to delete the report?
            </Typography>
          </Stack>
          <Stack direction="row" justify="flex-end">
            <Button color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleDeleteReport}>
              Delete
            </Button>
          </Stack>
        </Stack>
      </Modal>
    )
  }
)

export default DeleteReportModal
