import React from "react"
import clsx from "clsx"

import { Icon, IconProps } from "@components/ui/Icon/Icon"
import BasicButton, { BasicButtonProps } from "./BasicButton"

import styles from "./Button.module.scss"

export interface ButtonProps extends BasicButtonProps {
  fit?: boolean

  before?: React.ReactNode

  after?: React.ReactNode

  /**
   * @deprecated use before instead
   */
  startIcon?: IconProps

  /**
   * @deprecated use before instead
   */
  endIcon?: IconProps

  fullWidth?: boolean

  hidden?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>( // <== сделал проброс ref
  (
    {
      children,
      fullWidth = false,
      before,
      after,
      startIcon = null,
      endIcon = null,
      hidden = false,
      color = "primary",
      className,
      ...rest
    },
    ref
  ) => {
    const classNames = clsx(
      styles.root,
      {
        [styles.hidden]: hidden,
        [styles.fullWidth]: fullWidth,
      },
      className
    )
    return (
      <BasicButton {...rest} className={classNames} color={color} ref={ref}>
        {before != null ? (
          <span className={styles.before}>{before}</span>
        ) : null}

        {startIcon ? (
          <Icon className={styles.startIcon} {...startIcon} />
        ) : null}

        {children}

        {endIcon ? <Icon className={styles.endIcon} {...endIcon} /> : null}

        {after != null ? <span className={styles.after}>{after}</span> : null}
      </BasicButton>
    )
  }
)

export default Button
