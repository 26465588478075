import React, { FC, useState } from "react"
import Modal from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Label from "@components/ui/Label/Label"
import TextField from "@components/ui/TextField/TextField"
import { useAlert } from "react-alert"
import styles from "./Modals.module.scss"

interface EditReportNameModalProps {
  reportName: string
  isOpen: boolean
  onClose: () => void
  onEditReport: (name: string) => void
}

const EditReportNameModal: FC<EditReportNameModalProps> = ({
  reportName,
  onClose,
  isOpen,
  onEditReport,
}) => {
  const alert = useAlert()
  const [name, setName] = useState<string>(reportName)

  const handleEditNameReport = () => {
    if (!name) {
      alert.error("Please provide a report name")
      return
    }
    onEditReport(name)
  }
  return (
    <Modal
      title={`Edit ${reportName}`}
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modalBase}
    >
      <Stack align="stretch">
        <Stack direction="column" justify="space-between" align="stretch">
          <Label label="Report name">
            <TextField value={name} onChange={(e) => setName(e.target.value)} />
          </Label>
        </Stack>
        <Stack direction="row" justify="flex-end">
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleEditNameReport}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default EditReportNameModal
