import React from "react"

import isEmpty from "lodash/isEmpty"
import { MetricDescription, MetricsTrendType } from "@framework/types/metrics"
import { getTrendGradientPosition, gradient, gradientReverted } from "../utils"

import styles from "./TempScale.module.sass"

interface TempScaleProps {
  metric: MetricDescription
  metricsTrend: MetricsTrendType
  value?: number
}

const POSITION: Record<number, string> = {
  0: "10%",
  1: "30%",
  2: "50%",
  3: "70%",
  4: "90%",
}

export const TempScale: React.FC<TempScaleProps> = React.memo(
  ({ value = 0, metric, metricsTrend }) => {
    const positionPercentage = getTrendGradientPosition(
      metricsTrend[metric.name]?.trend,
      value
    )
    const gradientOrder =
      metric.trendDirection === "reversed" ? gradientReverted : gradient

    const getIndexOfPositionGradient: number = Object.keys(
      gradientOrder
    ).findIndex((percentage) => percentage === positionPercentage)
    if (isEmpty(metricsTrend) || !metricsTrend[metric.name]) return null
    return (
      <section
        className={styles.root}
        title={`Different from average ${(value * 100).toFixed(2)}%`}
      >
        <div className={styles.scale}>
          {Object.values(gradientOrder).map((color) => (
            <div
              className={styles.scaleItem}
              style={{ background: color }}
              key={color}
            />
          ))}
        </div>
        <span
          className={styles.knob}
          style={{ top: POSITION[getIndexOfPositionGradient] }}
        />
      </section>
    )
  }
)

export default TempScale
