import React from "react"
import { MetricDescription, MetricsTrendType } from "@framework/types/metrics"
import clsx from "clsx"

import { ViewCellProps } from "../types"
import MetricValueChip from "../../MetricValueChip"

import styles from "./TableCell.module.scss"

export interface MetricCellProps extends ViewCellProps<any> {
  getMetric: (name: string) => MetricDescription
  metricsTrend: MetricsTrendType
}

export const MetricCell: React.FC<MetricCellProps> = React.memo(
  ({ name, data, getMetric, metricsTrend }) => {
    const value = data?.[name]
    const metric = getMetric(name)

    if (typeof value !== "object")
      return (
        <div className={clsx(styles.metric, styles.defaultPaddings)}>-</div>
      )

    return (
      <MetricValueChip
        value={value}
        metric={metric}
        className={clsx(styles.metric, styles.defaultPaddings)}
        metricsTrend={metricsTrend as MetricsTrendType}
      />
    )
  }
)

export default MetricCell
