import React from "react"
import clsx from "clsx"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import { Button } from "@components/ui/Button"
import Stack from "@components/ui/Stack/Stack"

import styles from "./ReportGroup.module.sass"

interface ReportGroupProps {
  title: string
  total: number
  titleOverflow?: boolean
  showAll?: boolean
  className?: string
  children?: React.ReactNode
  onToggleClick?: React.MouseEventHandler
}

export const ReportGroup: React.FC<ReportGroupProps> = ({
  title,
  titleOverflow = false,
  total,
  showAll = false,
  className,
  onToggleClick: onToggle,
  children,
}) => {
  const rootRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    if (showAll)
      rootRef.current?.scrollIntoView({ block: "start", behavior: "smooth" })
  }, [showAll])

  return (
    <Box
      ref={rootRef}
      className={clsx(styles.root, className)}
      elevation={1}
      onClick={onToggle}
    >
      <div className={styles.header}>
        <Stack
          className={styles.groupHeader}
          direction="row"
          align="center"
          gutter="4"
        >
          <Typography
            className={clsx({ [styles.titleOverflow]: titleOverflow })}
            type="h3"
          >
            {title}
          </Typography>
          <Typography type="body2" className={styles.titleTooltip}>
            {title}
          </Typography>
          <Typography type="h4" color="black100Color" weight="bold">
            ({total})
          </Typography>
        </Stack>

        {showAll && (
          <Button
            color="secondary"
            size="small"
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              onToggle?.(event)
            }}
          >
            Back to overview
          </Button>
        )}
      </div>

      <div className={styles.body}>{children}</div>
    </Box>
  )
}

export default ReportGroup
