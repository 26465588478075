import {
  MetricsTrendType,
  MetricType,
  PercentageTrendRange,
} from "@framework/types/metrics"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"

export type PercentageTrendRangeKeys = keyof PercentageTrendRange
export const gradient: Record<PercentageTrendRangeKeys, string> = {
  "0 - 20%": "var(--metric_0-20_color)",
  "20% - 40%": "var(--metric_20-40_color)",
  "40% - 60%": "var(--metric_40-60_color)",
  "60% - 80%": "var(--metric_60-80_color)",
  "80% - 100%": "var(--metric_80-100_color)",
}
export const gradientReverted: Record<PercentageTrendRangeKeys, string> = {
  "0 - 20%": "var(--metric-reverted_0-20_color)",
  "20% - 40%": "var(--metric-reverted_20-40_color)",
  "40% - 60%": "var(--metric-reverted_40-60_color)",
  "60% - 80%": "var(--metric-reverted_60-80_color)",
  "80% - 100%": "var(--metric-reverted_80-100_color)",
}

export const getTrendGradientColor = (
  trend: PercentageTrendRange,
  value: number | undefined,
  trendDirection?: "reversed" | "normal"
) => {
  if (value && trend) {
    let result = ""
    if (trendDirection === "reversed") {
      Object.entries(trend).forEach(([key, val]) => {
        if (val.min <= value && value <= val.max) {
          // @ts-ignore
          result = gradientReverted[key]
        }
      })
    } else {
      Object.entries(trend).forEach(([key, val]) => {
        if (val.min <= value && value <= val.max) {
          // @ts-ignore
          result = gradient[key]
        }
      })
    }
    return result
  }
  return "transparent"
}

export const getTrendGradientPosition = (
  trend: PercentageTrendRange,
  value: number | undefined
) => {
  if (value && trend) {
    let result = ""
    Object.entries(trend).forEach(([key, val]) => {
      if (val.min <= value && value <= val.max) {
        // @ts-ignore
        result = key
      }
    })
    return result
  }
  return "40% - 60%"
}

function createPercentageRanges(
  min: number,
  max: number
): PercentageTrendRange {
  const range = (max - min) / 5
  return {
    "0 - 20%": { min, max: min + range },
    "20% - 40%": { min: min + range, max: min + 2 * range },
    "40% - 60%": { min: min + 2 * range, max: min + 3 * range },
    "60% - 80%": { min: min + 3 * range, max: min + 4 * range },
    "80% - 100%": { min: min + 4 * range, max },
  }
}
export const calculateMetricsTrend = (
  data: AnalyticsCard[],
  activeMetrics: string[]
) => {
  const result: MetricsTrendType = {}
  activeMetrics.forEach((metric) => {
    const metricName = metric
    data.forEach((card) => {
      const m = card[metricName] as MetricType
      if (m.Value) {
        result[metricName] = {
          ...result[metricName],
          min: Math.min(result[metricName]?.min || 0, Number(m.Value)),
          max: Math.max(result[metricName]?.max || 0, Number(m.Value)),
        }
      }
    })
  })
  Object.entries(result).forEach(([metricName, value]) => {
    result[metricName].trend = createPercentageRanges(value.min, value.max)
  })
  return result
}

export default null
