import React, { useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import Image from "@components/ui/Image/Image"
import AreaChart from "@components/ui/Charts/AreaChart/AreaChart"
import Stack from "@components/ui/Stack/Stack"

import {
  AnalyticsCard,
  DropOffHistogramEntity,
} from "@framework/types/creativeAnalytics"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import styles from "./MediaSection.module.sass"

interface MediaSectionProps {
  data: AnalyticsCard
  dropOffHistogramData: DropOffHistogramEntity[]
  isLoading: boolean
}

export const MediaSection: React.FC<MediaSectionProps> = observer(
  ({ data, dropOffHistogramData, isLoading }) => {
    const labels = dropOffHistogramData.map((item) => item.Timeframe)
    const points = dropOffHistogramData.map((item) => item.DropOff)
    const [isIframeLoading, setIsIframeLoading] = useState<boolean>(true)
    const handleOnIFrameLoad = () => {
      setIsIframeLoading(false)
    }
    return (
      <Box className={clsx(styles.root)} elevation={1}>
        <div className={styles.mediaContainer}>
          {data?.Type === "IMAGE" ? (
            <Image
              src={data.ThumbnailURL}
              className={styles.image}
              alt="Preview"
            />
          ) : data?.Type === "VIDEO" ? (
            <div className={styles.videoWrap}>
              {isIframeLoading && (
                <div className={styles.videoLoader}>
                  <CircleLoader />
                </div>
              )}
              {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
              <iframe
                src={data?.VideoURL}
                className={styles.video}
                onLoad={handleOnIFrameLoad}
              />
            </div>
          ) : null}

          <Typography
            className={styles.views}
            type="body1"
            color="black03Color"
            title="People are watching"
          >
            <Icon name="eye" />
            {/* {numberWithCommas(data.totalViews ?? 0)} */}
          </Typography>
        </div>

        {data?.Type === "VIDEO" && (
          <div className={styles.trendContainer}>
            {isLoading && (
              <div className={styles.trendLoader}>
                <CircleLoader />
              </div>
            )}
            <Stack gutter="4">
              <Typography type="h4" weight="bold">
                Drop-off trend
              </Typography>
              <Typography type="caption2" color="black60Color">
                Video duration:{" "}
                {isLoading
                  ? "Loading..."
                  : Math.max(...labels.map((item) => Number(item)))}
              </Typography>
            </Stack>

            <div className={styles.chart}>
              <AreaChart labels={labels} data={points} />
            </div>
          </div>
        )}
      </Box>
    )
  }
)

export default MediaSection
