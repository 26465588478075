import { getMetricDescriptor } from "@framework/constants/metrics"
import {
  MetricDescription,
  MetricsTrendType,
  PerformanceReport,
} from "@framework/types/metrics"
import { ColumnMapper } from "../components/Table/types"
import MetricCell from "../components/Table/TableCell/MetricCell"
import LabelCell from "../components/Table/TableCell/LabelCell"
import { makeDescriptor } from "../components/Table/utils"

const defaultLabelCell = makeDescriptor(LabelCell)

const defaultMetricCell = makeDescriptor(MetricCell, {
  getMetric: getMetricDescriptor,
})

const mapper = (
  activeMetrics: MetricDescription[],
  metricsTrend: MetricsTrendType
): ColumnMapper<PerformanceReport & { Name: string }>[] => {
  const res: ColumnMapper<PerformanceReport & { Name: string }>[] = [
    {
      name: "Name",
      label: "Name",
      width: "300px",
      view: defaultLabelCell,
    },
  ]
  activeMetrics.forEach((metric) => {
    res.push({
      name: metric.name,
      label: metric.label,
      width: "170px",
      view: defaultMetricCell,
      isSortable: true,
      metricsTrend,
    })
  })
  return res
}

export default mapper
