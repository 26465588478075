import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import { ButtonGroup } from "@components/ui/Button"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"
import Typography from "@components/ui/Typography/Typography"
import Chip from "@components/ui/Chip/Chip"
import { useStore } from "@root/store"
import { apiDateFormatter } from "@services/utils"
import {
  AnalyticsFilter,
  defaultMetrics,
  filterEqualityTypes,
  filterIncludesTypes,
  groupByObjectOptions,
  metricOptions,
} from "@framework/types/creativeAnalytics"
import { useAlert } from "react-alert"
import { printOnlyDateRange } from "@utils/date"
import styles from "./ReportControlPanelStatic.module.scss"

interface ReportControlPanelProps {
  onAppliedFilters: (filters: AnalyticsFilter[][]) => void
  accountId: number
  token: string
  onMetricsFilter?: (metrics: string[]) => void
}

const metricsSortByOptionsArray: { value: string; label: string }[] = []
Object.entries(metricOptions).forEach(([key, value]) => {
  metricsSortByOptionsArray.push({
    value: key,
    label: value,
  })
})

const groupByOptionsArray = Object.entries(groupByObjectOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

const ReportControlPanelStatic: React.FC<ReportControlPanelProps> = observer(
  ({ onAppliedFilters, onMetricsFilter, accountId, token }) => {
    const {
      creativeAnalyticsStore: {
        getADSCards,
        adsCardsData,
        reportData,
        isLoading,
      },
      analyticsFiltersStore: { allFilters, setAllFilters },
    } = useStore()

    const alert = useAlert()

    const { view, groupingType, period } = allFilters
    const [metricOptionsValues, setMetricOptionsValues] =
      useState<string[]>(defaultMetrics)
    const [appliedFiltersStrings, setAppliedFiltersStrings] = useState<
      string[]
    >([])
    const [appliedFilters, setAppliedFilters] = useState<AnalyticsFilter[][]>(
      []
    )

    useEffect(() => {
      if (
        reportData?.settings?.time_period?.start_date &&
        accountId &&
        token &&
        reportData?.id !== 0
      ) {
        getADSCards(accountId, {
          from: apiDateFormatter(
            new Date(reportData.settings.time_period.start_date)
          ),
          to: apiDateFormatter(
            new Date(reportData.settings.time_period.end_date)
          ),
          token,
        }).then((res) => {
          if (res) alert.error(res)
        })
      }
    }, [reportData, accountId, token])

    useEffect(() => {
      onAppliedFilters(appliedFilters)
    }, [appliedFilters, reportData, adsCardsData])

    useEffect(() => {
      if (reportData && reportData.id !== 0) {
        const isWrongGroupingType = groupByOptionsArray.find(
          (opt) => opt.value === reportData.settings.group
        )
        if (groupingType !== "None" && !isWrongGroupingType)
          alert.error(`Wrong grouping type: [${groupingType}]`)

        if (reportData.settings.filters) {
          const result: string[] = []
          const res: AnalyticsFilter[][] = reportData?.settings.filters.map(
            (f) => [
              { key: 1, value: f.name },
              { key: 2, value: f.comparator },
              { key: 3, value: f.value },
            ]
          )
          res.forEach((filter) => {
            const f = filter.map((it) => it.value).join(" ")
            result.push(f)
          })
          setAppliedFiltersStrings([...result])
          setAppliedFilters([...res])
        }
        if (reportData.settings.sort_option?.type) {
          setAllFilters({
            ...allFilters,
            sortByMetric: {
              value: reportData.settings.sort_option.type,
              order: reportData.settings.sort_option.order,
            },
          })
        }
        if (reportData.settings.metrics.length) {
          setMetricOptionsValues(reportData.settings.metrics)
        }
      }
    }, [reportData, adsCardsData])

    useEffect(() => {
      if (onMetricsFilter) onMetricsFilter(metricOptionsValues)
    }, [metricOptionsValues])

    useEffect(() => {
      if (onMetricsFilter) onMetricsFilter(metricOptionsValues)
    }, [])

    useEffect(() => {
      const campaigns: Set<string> = new Set()
      const adSets: Set<string> = new Set()
      if (adsCardsData.AdCreatives.length) {
        adsCardsData.AdCreatives.forEach((c) => {
          if (c.CampaignName) {
            campaigns.add(c.CampaignName)
          }
        })
        adsCardsData.AdCreatives.forEach((c) => {
          if (c.AdsetName) {
            adSets.add(c.AdsetName)
          }
        })
      }
    }, [adsCardsData])

    if (isLoading) return <div />

    return (
      <Box>
        <Stack
          className={styles.reportControlPanel}
          direction="row"
          gutter="8"
          justify="space-between"
        >
          <Stack direction="row" gutter="8">
            <Typography type="h2">
              {printOnlyDateRange(period?.range)}
            </Typography>
          </Stack>
          <Stack direction="row" gutter="8" justify="flex-end">
            <ButtonGroup>
              <IconButton
                variant="contained"
                color={view === "card" ? "primary" : "secondary"}
                onClick={() => {
                  setAllFilters({ ...allFilters, view: "card" })
                }}
              >
                <Icon name="dashboard1" />
              </IconButton>
              <IconButton
                variant="contained"
                color={view === "table" ? "primary" : "secondary"}
                onClick={() => {
                  setAllFilters({ ...allFilters, view: "table" })
                }}
              >
                <Icon name="menu" />
              </IconButton>
            </ButtonGroup>
          </Stack>
        </Stack>

        {appliedFiltersStrings.length ? (
          <div className={styles.filteredBy}>
            {appliedFiltersStrings.map((filter, index) => {
              if (!filter) return null
              const filtersStringArray = filter.split(" ")
              const metric =
                filtersStringArray[0].charAt(0).toUpperCase() +
                filtersStringArray[0].slice(1)
              const comparator =
                Object.entries(filterEqualityTypes).find(
                  ([key]) => key === filtersStringArray[1]
                )?.[1] ??
                Object.entries(filterIncludesTypes).find(
                  ([key]) => key === filtersStringArray[1]
                )?.[1]
              const value = filtersStringArray.slice(2).join(" ")
              return (
                <Chip
                  type="outlined"
                  color="primary"
                  endIcon={<Icon name="cross" />}
                  className={styles.filterChip}
                  key={filter}
                >
                  {`${metric} ${comparator} ${value}`}
                </Chip>
              )
            })}
          </div>
        ) : null}
      </Box>
    )
  }
)

export default ReportControlPanelStatic
